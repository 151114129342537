import { AnimatePresence, motion } from "framer-motion";
import { Globe2, Home, User2 } from "lucide-react";
import { useEffect, useState, useTransition } from "react";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const UserMenuNavigation = ({ path }: any) => {
	const [state, setState] = useState(path);
	const [isLoading, setIsLoading] = useState(false); // Nuevo estado para manejar la carga

	const homeVariants = {
		black: { backgroundColor: "black", color: "green" },
		white: { backgroundColor: "green", color: "black" },
	};
	useEffect(() => {
		setState(path);
	}, [path]);

	useEffect(() => {
		setState(path);
	}, [path]);

	const handleMenuClick = (newState: any) => {
		setIsLoading(true); // Inicia la carga
		window.location.href = newState;
		setTimeout(() => {
			setIsLoading(false); // Esto probablemente nunca se ejecute ya que la página se recargará
		}, 200); // 3000 milisegundos = 3 segundos
	};

	const backgroundColor = isLoading ? "green" : "white";

	console.log("path", path);
	return (
		<div className="fixed mb-5 bottom-0 w-full h-14 rounded-full z-30 px-2">
			<motion.div
				transition={{ duration: 0.4 }}
				id="padre"
				style={{ backgroundColor: backgroundColor }}
				className="relative transition-all ring-2 max-w-sm px-2 w-full mx-auto h-14 rounded-full flex-row flex items-center justify-between shadow-2xl"
			>
				<motion.div
					style={{
						backgroundColor: state === "/" ? "black" : "white",
						color: state === "/" ? "white" : "black",
						transition: "0.6s",
					}}
					onClick={() => handleMenuClick("/")}
					className="px-4 py-2 rounded-3xl cursor-pointer"
				>
					<div className="flex flex-row">
						<Home className={` ${state === "/" && "mr-2"}`} />
						<AnimatePresence>
							{state === "/" && (
								<motion.div
									initial={{ opacity: 0, width: 0 }}
									animate={{ opacity: 1, width: "auto" }}
									exit={{ opacity: 0, width: 0 }}
									transition={{ duration: 0.4 }}
									style={{ overflow: "hidden" }}
								>
									<span className="font-sans">Home</span>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</motion.div>
				<motion.div
					style={{
						transition: "0.6s",
						backgroundColor: state === "/plan" ? "black" : "white",
						color: state === "/plan" ? "white" : "black",
					}}
					onClick={() => handleMenuClick("/plan")}
					className="px-4 py-2 rounded-3xl bg-blue-200 cursor-pointer"
				>
					<div className="flex flex-row">
						<Globe2
							className={`${
								state === "/plan" ? "text-white mr-2" : "text-black"
							}`}
						/>
						<AnimatePresence>
							{state === "/plan" && (
								<motion.div
									initial={{ opacity: 0, width: 0 }}
									animate={{ opacity: 1, width: "auto" }}
									exit={{ opacity: 0, width: 0 }}
									transition={{ duration: 0.4 }}
									style={{ overflow: "hidden" }}
								>
									<span className="font-sans">Explore</span>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</motion.div>
				<motion.div
					style={{
						transition: "0.6s",
						backgroundColor: state === "/profile" ? "black" : "white",
						color: state === "/profile" ? "white" : "black",
					}}
					onClick={() => handleMenuClick("/profile")}
					className="px-4 py-2 rounded-3xl bg-blue-200 cursor-pointer"
				>
					<div className="flex flex-row">
						<User2
							className={`${
								state === "/profile" || state === "/login"
									? "text-white mr-2"
									: "text-black"
							}`}
						/>
						<AnimatePresence>
							{state === "/profile" && (
								<motion.div
									initial={{ opacity: 0, width: 0 }}
									animate={{ opacity: 1, width: "auto" }}
									exit={{ opacity: 0, width: 0 }}
									transition={{ duration: 0.4 }}
									style={{ overflow: "hidden" }}
								>
									<span className="font-sans">Profile</span>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default UserMenuNavigation;
